.carousel-progress {
  height: 0.15em !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-top: -8px !important;
  border-radius: 25px !important;
}

.carousel-progress .bar {
  background: white !important;
  opacity: 0.8 !important;
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
