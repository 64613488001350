.ListingImages-isBlurred {
  filter: blur(5px);
}

.ListingImages-login {
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
  margin: auto;
}

.hero-image {
  height: 500px;
  background-image: linear-gradient(to bottom, #ffff 0%, #cecece 100%);
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 25px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  position: relative;
  display: block;
  transition: 0.5s ease-in;
}

.scroll-grid.blurred {
  filter: blur(5px);
}

.hero-image.more {
  height: 700px;
}

.scroll-grid {
  height: 500px !important;
  overflow-y: hidden !important;
  transition: 0.5s ease-in;
}

.scroll-grid.more {
  height: 625px !important;
  overflow-y: auto !important;
}

.view-more-btn {
  position: absolute !important;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 99;
  border: none !important;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.1)
  ) !important;
  color: white;
}

.view-more-btn > .ui.button.basic.inverted,
.view-more-btn > .ui.button.basic.inverted:focus {
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-width: 2px !important;
}

.view-more-btn::before {
  content: "";
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.3)
  ) !important;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  filter: blur(5px);
  border-radius: 50%;
  transform: translateY(30px);
}

.close-btn {
  position: fixed;
  top: 1em;
  right: 1em;
}
.container.sub-contain {
  padding-top: 0px !important;
  height: inherit;
}

.hero-image-slick {
  max-height: 85vh;
  max-width: 85vw;
  margin-left: auto;
  margin-right: auto;
}

.masonry-image {
  background-color: #cecece;
}

.slideshow-image-selector {
  position: fixed;
  bottom: 5px;
  left: 0;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.slideshow-container {
  height: 100%;
  width: 100%;
}

.slideshow-container .center {
  vertical-align: top !important;
}

.slideshow-image-selector .item {
  padding: 5px !important;
  cursor: pointer !important;
  display: inline-block;
}

.slideshow-image-selector .item.selected {
  border: 2px #64c2ec solid;
}

.slideshow-image-selector .item {
  border: 2px transparent solid;
}

.slideshow-image-selector img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  object-position: center center;
}

.slideshow-image {
  height: calc(100vh - 300px);
  margin-top: -100px;
  object-fit: cover;
  object-position: center top;
  min-width: 800px;
  min-height: 600px;
  background-color: #eee;
  top: 0;
}

.slideshow-control {
  position: absolute;
  height: calc(50vh);
  display: flex;
  align-items: center;
  width: 75px;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  top: calc(25vh);
  border: none;
  color: white;
}

.slideshow-control:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.slideshow-control.left {
  left: 0;
}
.slideshow-control.right {
  right: 0;
}

@media only screen and (max-width: 480px) {
  .slideshow-image {
    width: 100vw;
    height: auto;
  }

  .slideshow-container .center {
    vertical-align: middle !important;
  }

  .slideshow-control {
    display: none;
  }
}

.ui.container.listing-images-collage,
.ui.grid.listing-images-collage {
  height: 225px;
  padding: 0px !important;
  margin: 0px !important;
  margin-bottom: -10px !important;
}
.ui.container.listing-images-collage {
  margin-top: 50px !important;
}
.listing-images-collage .stretched.row {
  padding: 0px !important;
}

.column.collage-image {
  padding: 0px !important;
  margin: 0px !important;
}

.collage-image picture {
  width: 100%;
  height: 100%;
}

.collage-image picture img,
.collage-image .inset {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.collage-image picture img,
.collage-image-count,
.collage-image .inset {
  border: 1px solid white;
}

.collage-image-count {
  position: absolute;
  top: 0;
  font-size: 2em;
  box-shadow: inset 0px 0px 90px black;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  padding-top: 30px;
  color: white;
  opacity: 0.8;
}

.collage-image .inset {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  box-shadow: inset 0px 0px 25px black;
  opacity: 0.4;
}
