.listing-card-container {
  /* margin: 10px; */
  padding: 15px !important;
  border-radius: 15px;
  cursor: pointer;
}

.ui.segment.listing-card,
.listing-card {
  padding: 0 !important;
  min-width: 250px;
  height: 300px;
  background-position: center !important;
  background-size: cover !important;
  margin-bottom: 1.2em;
  border-radius: 10px;
}

.listing-card.sm {
  height: 200px !important;
}

.listing-card .label {
  margin: 13px 0 0 13px !important;
}

.listing-card-footer {
  padding-left: 5px;
  padding-right: 5px;
}

.listing-card-footer > i {
  /* margin-top: px !important; */
  float: right !important;
  cursor: pointer !important;
  right: 15px;
  font-weight: 200;
}

.listing-card-footer .header {
  width: calc(100% - 35px);
}
.listing-card-footer > i.red.heart.big.icon {
  color: #ff6060 !important;
}

.listing-card-footer > a {
  color: #6e7983 !important;
}

.listing-card-footer > .ui.tiny.horizontal.list .list > .item,
.ui.tiny.horizontal.list > .item {
  font-size: 1.1rem !important;
}

.carousel-controls {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.carousel-controls > .carousel-link {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.carousel-controls > .ui.basic.inverted.button,
.carousel-controls > .ui.basic.inverted.button:hover,
.carousel-controls > .ui.basic.inverted.button:focus,
.carousel-controls > .ui.basic.inverted.button:active {
  box-shadow: none !important;
  border: none;
  position: absolute;
  height: 100%;
  top: 0;
}

.carousel-controls > .ui.basic.inverted.button > i {
  text-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
}

.carousel-controls > .prev-btn {
  left: 10;
  left: 0;
}

.carousel-controls > .next-btn {
  right: 0;
}

.bgimg-appear {
  opacity: 0.01;
}

.bgimg-appear.bgimg-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.bgimg-enter {
  opacity: 0.01;
}

.bgimg-enter.bgimg-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.bgimg-leave {
  opacity: 1;
}

.bgimg-leave.bgimg-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.listing-card-bg {
  height: 100%;
  width: 100%;
  object-position: center center;
  object-fit: cover !important;
  position: absolute;
  overflow: hidden;
  /* border-radius: 10px; */
}

.listing-card-footer .likelihood-label {
  font-size: 8pt;
  opacity: 0.7;
}

.listing-card-list-item-image picture {
  width: inherit;
  height: inherit;
  max-width: 175px;
  max-height: 175px;
}

.listing-card-list-item-image picture img {
  object-fit: cover;
  width: inherit;
  height: inherit;
  min-width: 135px;
  max-width: inherit;
  min-height: 150px;
  max-height: 150px;
}

.listing-card-list-item-container .header {
  font-size: 1em !important;
}

.heart-icon * {
  transition-timing-function: ease-in;
  transition-duration: 0.1s;
  transition-property: border-radius, background;
}

.heart-icon svg {
  padding: 2px;
}

.liked-heart-icon svg {
  background: #ff6060;
  color: white;
  border-radius: 50%;
  padding: 7px;
}

.content-placeholder {
  background: #f6f7f8;
}
.content-placeholder.loading {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: -webkit-linear-gradient(
    left,
    #eeeeee 8%,
    #dddddd 18%,
    #eeeeee 33%
  );
  margin-top: 5px;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -900px 0;
  }
  100% {
    background-position: 900px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -900px 0;
  }
  100% {
    background-position: 900px 0;
  }
}

@media only screen and (min-width: 768px) {
  .responsive-listing-card-grid .listing-card-container {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 1025px) {
  .responsive-listing-card-grid .listing-card-container {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 1600px) {
  .responsive-listing-card-grid .listing-card-container {
    width: 50% !important;
  }
}

@media only screen and (max-width: 1023px) {
  .responsive-listing-card-grid .listing-card-container {
    width: 100% !important;
  }
  .responsive-listing-card-grid .listing-cards {
    padding: 0 !important;
  }
}
